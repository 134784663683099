import React, {useState, useEffect} from 'react';
import { Box, Typography, Collapse, Alert, Button, Link, Stack, Tooltip, Divider, FormControlLabel,
  ClickAwayListener, IconButton, useMediaQuery, Chip } from '@mui/material';
import HelpIcon from '@mui/icons-material/Help';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import "./PricingScreen.css";
import { tooltipClasses } from '@mui/material/Tooltip';
import { styled } from '@mui/material/styles';
import pricingImage from '../assets/pricing.svg';
import checkmark from '../assets/check.svg';
import {useTheme} from '@mui/material/styles';
import { Helmet } from 'react-helmet-async';

const LightTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f5",
      color: 'rgba(0, 0, 0, 0.87)',
      boxShadow: theme.shadows[2],
      border: "1px solid black",
      fontSize: 14,
      textAlign: "center",
      maxWidth: 210,
    },
}));

const otherBlue = '#4069B2'

const PricingScreen = ({mode}) => {
  const isNotMobile = useMediaQuery("(min-width: 1100px)");
  const minScreen = useMediaQuery("(max-width: 1280px)");
  const theme = useTheme();
  const [bgClass, setBgClass] = useState("pricing-column");
  const tooSmall = useMediaQuery("(max-width: 520px)");
  const [proPrice, setProPrice] = useState(350);
  const [enterprisePrice, setEnterprisePrice] = useState(3500);
  const navigate = useNavigate();
  const mobile = useMediaQuery("(max-width: 600px)");
  const lower = useMediaQuery("(max-width: 900px)");

  const [openFreeAlert, setOpenFreeAlert] = useState(false);//already have standard sub
  const [perSub, setPerSub] = useState("/yr");
  const [checked, setChecked] = useState(true);

  const [openTooltips, setOpenTooltips] = useState({});

  // Function to handle opening a tooltip
  const handleTooltipOpen = (id) => {
    setOpenTooltips({ ...openTooltips, [id]: true });
  };

  // Function to handle closing a tooltip
  const handleTooltipClose = (id) => {
    setOpenTooltips({ ...openTooltips, [id]: false });
  };

  const handleSwitch = (event) => {
    setChecked(!checked);
    if (checked) {
      setPerSub("/mo");
      setProPrice(35);
      setEnterprisePrice(350);
    } else {
      setPerSub("/yr");
      setProPrice(350);
      setEnterprisePrice(3500);
    }
  };

  //useEffect for mode
  useEffect(() => {
    if (mode === 'light') {
      setBgClass("pricing-column");
    } else {
      setBgClass("pricing-column-dark");
    }
  }, [mode]);

  const freeHandler = async (e) => {
    e.preventDefault();
    try {
      if (!localStorage.getItem("authToken")) {
        setOpenFreeAlert(true);
        //close alert after 5 seconds
        setTimeout(() => {
            setOpenFreeAlert(false);
        }, 5000);
        return;
      } else {
        navigate("/dashboard");
      }
    } catch (error) {
        console.log(error);
    }
  }

  const proHandler = async (e) => {
    e.preventDefault();
    try {
        if (!localStorage.getItem("authToken")) {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data}`
                },
            };
            const usr = await axios.get(`/api/auth/subscription`, config);
            const customerId = await axios.get(`/api/auth/customer`, config);

            if (usr.data.subscription) {
              if (customerId.data.customerId) {
                  const portal = await axios.post("/api/stripe/portal", { customerId: customerId.data.customerId }, config);
                  if (portal.data.url) {
                      window.open(portal.data.url, "_self");
                  }
              } else {
                if (checked)
                  navigate(`/checkout/pro-yearly`);
                else
                  navigate(`/checkout/pro`);
              }
          } else {
            if (checked)
              navigate(`/checkout/pro-yearly`);
            else
              navigate(`/checkout/pro`);
          }
        } else {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }

  const premiumHandler = async (e) => {
    e.preventDefault();
    try {
      if (!localStorage.getItem("authToken")) {
        setOpenFreeAlert(true);
        setTimeout(() => { setOpenFreeAlert(false); }, 5000);
        return;
      }
        const res = await axios.get('/api/auth/refresh-token');
        if (res.data) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${res.data}`
                },
            };
            const usr = await axios.get(`/api/auth/subscription`, config);
            const customerId = await axios.get(`/api/auth/customer`, config);
            
            if (usr.data.subscription) {
                if (customerId.data.customerId) {
                    const portal = await axios.post("/api/stripe/portal", { customerId: customerId.data.customerId }, config);
                    if (portal.data.url) {
                        window.open(portal.data.url, "_self");
                    }
                } else {
                  if (checked)
                    navigate(`/checkout/enterprise-yearly`);
                  else
                    navigate(`/checkout/premium`);
                }
            } else {
              if (checked)
                navigate(`/checkout/enterprise-yearly`);
              else
                navigate(`/checkout/premium`);
            }
            
        } else {
            setOpenFreeAlert(true);
            setTimeout(() => { setOpenFreeAlert(false); }, 5000);
            return;
        }
    } catch (error) {
        
        if (error.response.data.url)
            window.open(error.response.data.url, "_self");
        console.log(error.response.data);
    }
  }

  //useEffect to check cookies for ref
  /*useEffect(() => {
    if (Cookies.get('ref')) {
      setEnterprisePrice(300);
      setProPrice(25);
    }
  }, []);*/


    return (
      <Box sx={{letterSpacing: '0.5px'}}>
        <Helmet>
          <title>Pricing | PIEARM™</title>
          <meta name="description" content="Get marketing copy fast! Let PIEARM.AI do the heavy lifting for you with one of our affordable pricing plans now." />
        </Helmet>
          <Stack direction="row" alignItems="center" spacing={4} mb="9vh" pt="18vh">
            <Box flex={1} />
            {!mobile && <img src={pricingImage} alt="pricing" style={{ height: lower ? (mobile ? 0 : 340) : 380 }} />}
            <Box maxWidth={540} pb={2.2}>            
                <Typography fontSize={lower ? (mobile ? 40 : 48) : 52} fontWeight={650} color={mode === 'light' ? "primary" : otherBlue}>Pricing</Typography>
                <Typography variant={mobile ? "h5" : "h4"} color={mode === 'light' ? otherBlue : "primary"} sx={{mb: 0.5}}> Get marketing copy fast! </Typography> 
                <Typography variant={mobile ? "h5" : "h4"} fontWeight={300}> Let PIEARM.AI do the heavy lifting for you. Get started with one of our affordable pricing plans now. </Typography> 
            </Box>
            <Box flex={1} />
          </Stack>
            <Box width="100%" display="flex" justifyContent="center" alignItems="center" mb={6}>
              <Stack direction="row" spacing={1} alignItems="center" bgcolor="primary.light" borderRadius={10} py={0.8} px={1.5}>
                <Box borderRadius={10} py={.5} px={1}
                sx={{bgcolor: checked ? "inherit" : mode === 'light' ? "primary.main" : "primary.dark", cursor: "pointer" }} onClick={handleSwitch}>
                  <Typography fontSize={mobile ? 16 : 20} color={!checked ? "white" : mode === 'light' ? "primary.main" : "primary.dark"} fontWeight={400}>Pay Monthly</Typography>
                </Box>
                <Stack borderRadius={10} py={.5} px={1} direction="row" alignItems="center" spacing={1}
                sx={{bgcolor: checked ? mode === 'light' ? "primary.main" : "primary.dark" : "inherit", cursor: "pointer" }} onClick={handleSwitch}>
                  <Typography fontSize={mobile ? 16 : 20} color={checked ? "white" : mode === 'light' ? "primary.main" : "primary.dark"} fontWeight={400}>Pay Yearly </Typography>
                  <Typography fontWeight={900} sx={{color: !checked ? mode === 'light' ? "primary.main" : "primary.dark" : "white", fontSize: mobile ? 13 : 16}}>Save 16%</Typography>
                </Stack>
              </Stack>             
            </Box>
            <Stack
              direction={!isNotMobile ? "column" : "row"}
              spacing={!isNotMobile ? '6vh' : '1.8vw'}
              alignItems={!isNotMobile ? "center" : "start"}
              pt={1}
              px={!isNotMobile ? 4 : '5vw'}
            >
              <Box borderRadius={10} px={4} py={5} flex={1} width={!isNotMobile ? "100%" : "28vw"}
                minHeight={532} border="2px solid #C9E8F8" boxShadow="0px 3px 5px 0px #b0b0b0"
                display="flex" flexDirection="column" justifyContent="space-between"
              >
                  <div>
                      <Typography variant="h1" fontWeight={700} color={otherBlue} textAlign="center" sx={{mb:2}}>
                            Basic
                      </Typography>
                      <Typography fontWeight={700} fontSize={60} color="primary" textAlign="center">
                          $0<span style={{fontSize: 16, fontWeight: 500}}>{perSub}</span>
                      </Typography>
                      <ul style={{ padding: "0px 8px 0px 8px" }}>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>Up to 2,500 generated words per month </li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, color: otherBlue }}>1 User</li>
                      </ul>
                  </div>
                  <Button 
                      onClick={freeHandler} 
                      variant="outlined" 
                      fullWidth={true} 
                      disableElevation={true}
                      sx={{fontSize: 22, fontWeight: "bold", borderRadius: 10, mt:1,}}  // Added margin-bottom to ensure the button is 10px above the bottom
                  >
                      Sign Up for Free
                  </Button>
              </Box>
              <Box position="relative"
                  borderRadius={10} 
                  px={4} 
                  py={5} 
                  flex={1} 
                  width={!isNotMobile ? "100%" : "28vw"}
                  minHeight={532}  
                  border="2px solid #C9E8F8" 
                  boxShadow="0px 3px 5px 0px #b0b0b0"
                  display="flex" 
                  flexDirection="column" 
                  justifyContent="space-between"
              >
                <Chip 
                  label="Most Popular" 
                  color="primary" 
                  sx={{ 
                    position: 'absolute', 
                    top: '-18px',  // Adjust this value as necessary
                    left: '50%', 
                    transform: 'translateX(-50%)', 
                    zIndex: 1, 
                    fontSize: '24px',
                    paddingY: '20px',
                    paddingX: '30px',
                    fontWeight: '500',
                    borderRadius: 10,
                  }} 
                />
                  <Box>
                      <Typography variant="h1" fontWeight={700} color={otherBlue} textAlign="center" sx={{mb:2}}>
                          Pro
                      </Typography>
                      <Typography fontWeight={700} fontSize={60} color="primary" textAlign="center">
                          ${proPrice}<span style={{fontSize: 16, fontWeight: 500}}>{perSub}</span>
                      </Typography>
                      <ul style={{ padding: "0px 8px 0px 8px" }}>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>Up to 300,000 generated words per month </li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>Generate up to 4 variants of ad and social media copy at one time</li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, color: otherBlue }}>1 User</li>
                      </ul>
                  </Box>
                  <Button 
                      onClick={proHandler} 
                      variant="contained" 
                      fullWidth={true} 
                      disableElevation={true}
                      sx={{fontSize: 22, mt: 1, fontWeight: "bold", borderRadius: 10,}}  // Added margin-bottom to ensure the button is 10px above the bottom
                  >
                      {!localStorage.getItem("sub") ? "Upgrade" : localStorage.getItem("sub") === 'pro' ? "Billing Portal" : "Downgrade"}
                  </Button>
              </Box>
              <Box 
                  borderRadius={10} 
                  px={4} 
                  py={5} 
                  flex={1} 
                  width={!isNotMobile ? "100%" : "28vw"}
                  minHeight={532}  
                  border="2px solid #C9E8F8" 
                  boxShadow="0px 3px 5px 0px #b0b0b0"
                  display="flex" 
                  flexDirection="column" 
                  justifyContent="space-between"
              >
                  <div>
                      <Typography variant="h1" fontWeight={700} color={otherBlue} textAlign="center" sx={{mb:2}}>
                          Enterprise
                      </Typography>
                      <Typography fontWeight={700} fontSize={60} color="primary" textAlign="center">
                          ${enterprisePrice}<span style={{fontSize: 16, fontWeight: 500}}>{perSub}</span>
                      </Typography>
                      <ul style={{ padding: "0px 8px 0px 8px" }}>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>All features included in the Pro Tier </li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>Unlimited generated words per month</li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>1 User</li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, marginBottom: "2px", color: otherBlue }}>Priority customer support</li>
                          <li style={{ fontSize: "18px", lineHeight: 1.2, color: otherBlue }}>Custom advanced reporting, analysis, and automated lead gen</li>
                      </ul>
                  </div>
                  <Button 
                      onClick={premiumHandler} 
                      variant="contained" 
                      fullWidth={true} 
                      disableElevation={true}
                      sx={{fontSize: 22, borderRadius: 10, fontWeight: "bold", mt: 1,}}  // Added margin-bottom to ensure the button is 10px above the bottom
                  >
                      {!localStorage.getItem("sub") ? "Upgrade" : localStorage.getItem("sub") === 'pro' ? "Upgrade" : "Billing Portal"}
                  </Button>
              </Box>
          </Stack>

          <Box px={'5vw'}>
            {!tooSmall && <Stack pb={8} spacing={2} mt='10vh'>
              <Typography color="primary.main" fontSize={isNotMobile ? 52 : 48 } fontWeight={700} sx={{ textAlign: "center", mb: 4 }}>
                Compare features across plans
              </Typography>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 14 : 28} sx={{ flex: 1, textAlign: 'start', alignItems:'center', color: 'primary' }}>Features</Typography>
                <Typography fontWeight="bold" fontSize={mobile ? 14 : 28} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color={otherBlue}>Basic</Typography>
                <Typography fontWeight="bold" fontSize={mobile ? 14 : 28} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color={otherBlue}>Pro</Typography>
                <Typography fontWeight="bold" fontSize={mobile ? 14 : 28} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }} color={otherBlue}>Enterprise</Typography>
              </Stack>
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Word Limit
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip1')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip1')}
                    placement="right"
                    open={openTooltips['tip1'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Defines the maximum number of words you can generate each month.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip1')} onMouseEnter={() => handleTooltipOpen('tip1')}>
                                      <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>2,500 words</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>300,000 words</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Unlimited words</Typography>
                </Stack>
              </Stack>
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Platform Access
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip2')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip2')}
                    placement="right"
                    open={openTooltips['tip2'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Provides varying levels of access to platform features, from basic to full access.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip2')} onMouseEnter={() => handleTooltipOpen('tip2')}>
                                      <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Basic</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Extended</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Full</Typography>
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Content Export
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip3')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip3')}
                    placement="right"
                    open={openTooltips['tip3'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Allows exporting of content to various formats or sharing directly via multiple channels.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip3')} onMouseEnter={() => handleTooltipOpen('tip3')}>
                                      <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Ask PIEARM
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip4')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip4')}
                    placement="right"
                    open={openTooltips['tip4'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='PIEARM Offers a customizable chat assistant for any inquiries.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip4')} onMouseEnter={() => handleTooltipOpen('tip4')}>
                          <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Digital Strategy
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip5')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }}
                    onClose={() => handleTooltipClose('tip5')}
                    placement="right"
                    open={openTooltips['tip5'] || false}
                    disableFocusListener
                    disableTouchListener
                    title='Helps create detailed digital strategies for brands, companies, products, or services.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip5')} onMouseEnter={() => handleTooltipOpen('tip5')}>
                          <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Keyword Research & Content Planning
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip6')}>
                    <LightTooltip 
                    PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip6')} placement="right"
                    open={openTooltips['tip6'] || false} disableFocusListener disableTouchListener
                    title='Provides tools for keyword research, understanding search intent, cost per click and content planning advice.'
                    >
                        <IconButton onClick={() => handleTooltipOpen('tip6')} onMouseEnter={() => handleTooltipOpen('tip6')}>
                          <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                        </IconButton>
                    </LightTooltip>
                </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Content Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip7')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip7')} placement="right"
                      open={openTooltips['tip7'] || false} disableFocusListener disableTouchListener
                      title='Generates automated optimized content for ad copy, social media, blogs, websites, email marketing, and more.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip7')} onMouseEnter={() => handleTooltipOpen('tip7')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  View Saved Copies
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip8')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip8')} placement="right"
                      open={openTooltips['tip8'] || false} disableFocusListener disableTouchListener
                      title='Allows access to view and manage saved and previously shared content stored in your personal profile.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip8')} onMouseEnter={() => handleTooltipOpen('tip8')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Social Media Integration
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip9')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip9')} placement="right"
                      open={openTooltips['tip9'] || false} disableFocusListener disableTouchListener
                      title='Integrates directly with various social media platforms for seamless content sharing.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip9')} onMouseEnter={() => handleTooltipOpen('tip9')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Social Media Variants Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip10')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip10')} placement="right"
                      open={openTooltips['tip10'] || false} disableFocusListener disableTouchListener
                      title='Generates multiple versions of social media content to test effectiveness.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip10')} onMouseEnter={() => handleTooltipOpen('tip10')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Up to 4 variations</Typography>
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Up to 4 variations</Typography>
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Audio Transcriber
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip11')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip11')} placement="right"
                      open={openTooltips['tip11'] || false} disableFocusListener disableTouchListener
                      title='Converts MP3 files, wave files, or YouTube videos up to 30 minutes into text.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip11')} onMouseEnter={() => handleTooltipOpen('tip11')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Customer Support
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip12')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip12')} placement="right"
                      open={openTooltips['tip12'] || false} disableFocusListener disableTouchListener
                      title='Provides priority support for fast and personalized assistance to integrate Analytics and Reporting and AI Lead Generation.'
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip12')} onMouseEnter={() => handleTooltipOpen('tip12')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Priority support</Typography>
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  Analytics and Reporting
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip15')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip15')} placement="right"
                      open={openTooltips['tip15'] || false} disableFocusListener disableTouchListener
                      title="Offers custom Google Analytics KPIs integration and reports on content performance and user engagement."
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip15')} onMouseEnter={() => handleTooltipOpen('tip15')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Advanced features</Typography>
                </Stack>
              </Stack> 
              <Divider/>
              <Stack direction="row" spacing={2} sx={{ justifyContent: "space-around" }}>
                <Typography fontWeight="bold" fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}>
                  AI Lead Generation
                  <ClickAwayListener onClickAway={() => handleTooltipClose('tip16')}>
                      <LightTooltip 
                      PopperProps={{ disablePortal: true }} onClose={() => handleTooltipClose('tip16')} placement="right"
                      open={openTooltips['tip16'] || false} disableFocusListener disableTouchListener
                      title="Collects up to 20 legally compliant data points about your website visitors. Collect up to 100 leads a month. Additional leads are available at $1.00 per lead."
                      >
                          <IconButton onClick={() => handleTooltipOpen('tip16')} onMouseEnter={() => handleTooltipOpen('tip16')}>
                            <HelpIcon sx={{color: otherBlue, fontSize: isNotMobile ? 24 : 16}}/>
                          </IconButton>
                      </LightTooltip>
                  </ClickAwayListener>
                </Typography>
                <Typography sx={{ flex: 1, textAlign: 'start', alignItems:'center'  }}></Typography>
                <Typography fontSize={mobile ? 12 : 18} sx={{ flex: 1, textAlign: 'start', alignItems:'center' }}></Typography>
                <Stack direction="row" alignItems="center" flex={1} spacing={1}>
                  <img src={checkmark} alt="checkmark" height="18px" width="18px" />
                  <Typography fontSize={mobile ? 12 : 18}>Advanced features</Typography>
                </Stack>
              </Stack> 
              <Divider/>
            </Stack> }
        </Box>
      </Box>
    )
}

export default PricingScreen;