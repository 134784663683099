import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider, 
    InputAdornment, IconButton, Stack, Switch } from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from '@mui/x-data-grid';
import AdminRegisterModal from '../common/AdminRegisterModal';
import SubDropdown from '../common/SubDropdown';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const AdminScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [open, setOpen] = useState(false);
    const [promoCodes, setPromoCodes] = useState({});

    const handleClose = () => setOpen(false);

    useEffect(() => {
        window.scrollTo(0, 0);
        async function getAllUsers () {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data) {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                axios.get(`api/auth/admin-get-users`, config).then(res => {
                    setUsers(res.data);
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setError(err.response.data.error);
                })
            }
        }
        getAllUsers();
    }, []);

    useEffect(() => {
        if (query) {
            const timeout = setTimeout(async () => {
                setUsers([]);
                const token = await axios.get("/api/auth/refresh-token");
                if (token.data) {
                    const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                    axios.get(`/api/auth/search/${query}`, config)
                        .then(res => {
                            setUsers(res.data);
                        })
                        .catch(err => {
                            console.log(err);
                            setLoading(false);
                            setError(err.response.data.error);
                        })
                }
            }, 100);
            return () => clearTimeout(timeout);
        }
    }, [query]);

    const deleteUser = async (id) => {
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.data}`,
            },
          };
          try {
            await axios.delete(`/api/auth/admin-delete/${id}`, config);
            
            // Remove the deleted user from the state
            setUsers(prevUsers => prevUsers.filter(user => user._id !== id));
          } catch (err) {
            console.error(err);
            setError("Failed to delete user");
          }
        }
    };
    

    const generatePartnerCode = async (id) => {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data) {
                const config = {
                    headers: {
                        "Content-Type": "application/json",
                        Authorization: `Bearer ${token.data}`,
                    },
                };
                const res = await axios.post(`/api/stripe/promo`, { promoCode: promoCodes[id] }, config);
                if (res.data) {
                    const { data } = await axios.post(
                        `/api/auth/generate-partner-code`, { id: id, partnerCode: promoCodes[id] },
                        config
                    );
                    alert(`Partner code: ${data.partnerCode}`);
                    setUsers(users.map(user => user._id === id ? { ...user, partnerCode: data.partnerCode } : user));
                    //setQuery(users.find(user => user._id === id).email);
                }
            }
        } catch (error) {
            console.error('Error generating partner code:', error);
            setError('Error generating partner code');
        }
    };

    const handleSubscriptionChange = async (e, id) => {
        const newPlan = e.target.value;
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data) {
          const config = {
            headers: {
              "Content-Type": "application/json",
              Authorization: `Bearer ${token.data}`,
            },
          };
          await axios.put(
            `/api/auth/admin-change-plan/${id}`,
            { subscription: newPlan },
            config
          );
          const updatedUsers = users.map((user) =>
            user._id === id ? { ...user, subscription: newPlan } : user
          );
          setUsers(updatedUsers);
        }
    };

    const handlePromoCodeChange = (id, value) => {
        setPromoCodes(prevState => ({ ...prevState, [id]: value }));
    };

    const handleVerifyChange = async (id) => {
        const token = await axios.get("/api/auth/refresh-token");
        if (token.data) {
            const config = {
                headers: {
                    "Content-Type": "application/json",
                    Authorization: `Bearer ${token.data}`,
                },
            };
            await axios.post(`/api/auth/verify-user`, {id:id}, config);
            setUsers(users.map(user => user._id === id ? { ...user, verified: true } : user));
            //setQuery to user email
            //setQuery(users.find(user => user.id === id).email);
            //console.log(users.find(user => user._id === id))
        }
    };

    const mappedUsers = users.map(user => ({ id: user._id, ...user }));
    
    const formatDate = (date) => {
        const dateObj = new Date(date);
        return dateObj.toLocaleDateString('en-US', {
            month: '2-digit',
            day: '2-digit',
            year: 'numeric'
        });
    }

    const columns = [
        { field: 'email', headerName: 'Email', width: 250 },
        { field: 'createdAt', headerName: 'Join Date', width: 130, type: 'date',
            valueFormatter: (params) => formatDate(params.value), },
        { 
            field: 'wordsGenerated', 
            headerName: 'Words Generated', 
            width: 180 
        },
        { 
            field: 'subscription', 
            headerName: 'Subscription', 
            width: 150, 
            editable: true,
            renderCell: (params) => (
                <Box>
                    <SubDropdown plan={params.value} margB={0} size="small" fontS={12} setPlan={handleSubscriptionChange} userId={params.id}/>
                </Box>
            ),
        },
        { field: 'customerId', headerName: 'Customer ID', width: 200 },
        { 
            field: 'delete',
            headerName: 'Delete',
            width: 100,
            renderCell: (params) => (
                <Button sx={{textTransform: "none"}}
                onClick={() => deleteUser(params.id)} variant="contained" color="error" size="small">
                    Delete
                </Button>
            ),
        }, 
        { 
            field: 'partnerCode',
            headerName: 'Partner Code',
            width: 270,
            renderCell: (params) => (
                params.value ? (
                    <Stack direction="row" alignItems="center">
                        <Typography fontSize={14}>{params.value}</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText(params.value); alert('Partner code copied.')}}>
                            <ContentCopyIcon/>
                        </IconButton>
                    </Stack>
                ) : (
                    <Stack direction="row" alignItems="center" spacing={1}>
                        <TextField 
                            sx={{width: 120}}
                            size="small" 
                            value={promoCodes[params.id] || ""} 
                            onChange={(e) => handlePromoCodeChange(params.id, e.target.value)} 
                        />
                        <Button sx={{textTransform: "none"}}
                        onClick={() => generatePartnerCode(params.id)} variant="contained" color="primary" size="small">
                            Generate
                        </Button>
                    </Stack>
                )
            ),
        },
        {
            field: 'verified',
            headerName: 'Verified',
            width: 100,
            renderCell: (params) => (
                <Switch 
                    checked={params.value} 
                    onChange={() => handleVerifyChange(params.id)} 
                    disabled={params.value} 
                />
            ),
        }
    ];

    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError("");
            }, 10000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    
    return (
        <Box width={isNotMobile ? "80%" : "90%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "60vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={(e) => e.preventDefault()}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center" color="primary" mb={3}>Admin Dashboard</Typography>
                
                <Typography variant={isNotMobile ? "h6" : "body2"} fontWeight={500} mb={1}>User Lookup</Typography> 
                
                <TextField sx={{".MuiOutlinedInput-root": { fontSize: isNotMobile ? 16 : 14, borderRadius: 10 }, mb:isNotMobile ? 2 : 1}} 
                required size={isNotMobile ? "medium" : "small"} placeholder="Search by email, customerId, or subscription type" fullWidth value={query} 
                onChange={(e) => {setQuery(e.target.value);}} disabled={loading}
                InputProps={{
                    startAdornment: (
                    <InputAdornment >
                        <IconButton sx={{mr: 1}}>
                            <SearchIcon  sx={{color: loading? "gray" : "primary.main",}}/>
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            </form>

            <Typography mb={2}>OR</Typography>
            <Button variant="contained" onClick={()=>setOpen(true)} sx={{borderRadius: 10}} disableElvation={true}>Create User</Button>
            <AdminRegisterModal open={open} handleClose={handleClose} />

            { users && users.length > 0 &&
                <Box width="100%">
                    <Divider sx={{my: isNotMobile ? 4 : 2}}></Divider>

                    <Typography variant={isNotMobile ? "h4" : "h5"} fontWeight="bold" mb={2}> Results </Typography>

                    <div style={{ height: 'auto', width: '100%' }}>
                        <DataGrid
                            rows={mappedUsers}
                            columns={columns}
                            pageSize={10}
                        />
                    </div>

                    <Button variant="contained" onClick={() => { window.scrollTo({top: 0, behavior: "smooth"});}}
                        sx={{ fontWeight:"bold", textTransform: 'none', mt:2, fontSize: 14, borderRadius: 1, width: 140}}
                        startIcon={ <KeyboardDoubleArrowUpRoundedIcon/> }
                    > 
                        Back to top
                    </Button>
                </Box>
            }

            
        </Box>
    )
}

export default AdminScreen;
