import React from 'react' ;
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, IconButton} from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { useState, useEffect } from 'react';
import axios from 'axios';
import { convert } from 'html-to-text';
import {Link as ReactRouterLink} from 'react-router-dom';
import DeleteIcon from '@mui/icons-material/Delete';
import { useNavigate } from 'react-router-dom';

const SharedArticleScreen = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [loading, setLoading] = useState(false);
    const [id, setId] = useState("id");
    const [articles, setArticles] = useState([]);
    const [deleted, setDeleted] = useState("");
    const navigate = useNavigate();

    useEffect(() => {
        async function fetchHistories() {
        try {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const response = await axios.post('/api/article/shared', {}, config);
                let shared = response.data
                const {data} = await axios.get('/api/history/get-shared-histories', config);
                setArticles(shared.concat(data));
            }
        } catch (error) {
            console.error(error);
        }
        }

        fetchHistories();
    }, [deleted, id]);

    //useEffect for error
    useEffect(() => {
        const timeout = setTimeout(() => {
            setError("");
        }, 5000);
        //clear error
        return () => clearTimeout(timeout);

    }, [error]);

    const deleteArticle = async (id) => {
        try {
            setLoading(true);
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data && token.data !== "Unauthorized") {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                await axios.post(`/api/article/shared-false`, {id: id}, config);
                setDeleted(id)
                setLoading(false);
                navigate('/shares')
            } else {
                setLoading(false);
                setError("You are not authorized to use this feature. Please login.");
            }
        } catch (err) {
            setLoading(false);
            if (err.response.data.error) {
                setError(err.response.data.error);
            } else if (err.response.data.message) {
                setError(err.response.data.message);
            } else {
                setError("Something went wrong. Please try again later.");
            }
        }
    }

    return (
        <Box width={isNotMobile ? "50%" : "90%" } 
            p="2rem" 
            m="2rem auto"
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "40vh" : 30}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <Typography variant="h4" fontWeight="bold" mb={3}>Shared Articles</Typography>
            <FormControl fullWidth>
                <InputLabel id="article-simple-select-label">Article</InputLabel>
                <Select sx={{borderRadius: 10}}
                labelId="article-simple-select-label"
                id="article-simple-select"
                value={id}
                label="Article"
                onChange={(event) => setId(event.target.value)}
                >
                    {  articles && articles.map(article => (
                    <MenuItem value={article._id} component={ReactRouterLink} to={`/share/${article.title}`}>
                    <Typography>{ article.content ? (isNotMobile ? convert(article.content).substring(0,50) : convert(article.content).substring(0,22)) :
                        article.title}</Typography>
                    <IconButton 
                    onClick={(event) => {
                        event.preventDefault();
                        event.stopPropagation();
                        deleteArticle(article._id);
                      }}
                    >
                        <DeleteIcon />
                    </IconButton>
                </MenuItem>)
                ) }
                </Select>
            </FormControl>
        </Box>
    )
}

export default SharedArticleScreen;