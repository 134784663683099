import React from 'react';
import { Box, Typography, useTheme, useMediaQuery, Collapse, Alert, TextField, Button, Divider, 
    InputAdornment, IconButton, Stack} from '@mui/material';
import { useState, useEffect } from 'react';
import axios from 'axios';
import KeyboardDoubleArrowUpRoundedIcon from '@mui/icons-material/KeyboardDoubleArrowUpRounded';
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from '@mui/x-data-grid';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

const PartnerDashboard = () => { 
    const theme = useTheme();
    const isNotMobile = useMediaQuery("(min-width: 1000px)");
    const [error, setError] = useState("");
    const [query, setQuery] = useState("");
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState([]);
    const [filteredUsers, setFilteredUsers] = useState([]);
    const [partnerCode, setPartnerCode] = useState("");

    useEffect(() => {
        window.scrollTo(0, 0);
        async function getAllUsers () {
            const token = await axios.get("/api/auth/refresh-token");
            if (token.data) {
                const config = { headers: { "Content-Type": "application/json", Authorization: `Bearer ${token.data}` } };
                const {data} = await axios.get(`api/auth/partner-code`, config);
                //console.log(data)
                setPartnerCode(data.partnerCode);
                axios.get(`api/auth/referred-users`, config).then(res => {
                    setUsers(res.data);
                    setFilteredUsers(res.data); // Initialize filtered users with the full list
                    //console.log(res.data)
                })
                .catch(err => {
                    console.log(err);
                    setLoading(false);
                    setError(err.response.data.error);
                })
            }
        }
        getAllUsers();
    }, []);

    //useEffect for search
    useEffect(() => {
        const searchUsers = (list, query) => {
            return list.filter(user =>
              user.email.toLowerCase().includes(query.toLowerCase()) ||
              user.subscription.toLowerCase().includes(query.toLowerCase())
            );
        };
        if (query) {
            const timeout = setTimeout(() => {
                const searchedUsers = searchUsers(users, query);
                setFilteredUsers(searchedUsers);
            }, 100);
            return () => clearTimeout(timeout);
        } else {
            setFilteredUsers(users); // Reset to original list if query is empty
        }
    }, [query, users]);

    const mappedUsers = filteredUsers.map(user => ({ id: user._id, ...user }));
    
    const columns = [
        { field: 'email', headerName: 'Referred Users', width: 250 },
         {field: 'subscription', headerName: 'Subscription', width: 150},
    ];

    //useEffect for error
    useEffect(() => {
        if (error) {
            const timeout = setTimeout(() => {
                setError("");
            }, 10000);
            return () => clearTimeout(timeout);
        }
    }, [error]);

    return (
        <Box width={isNotMobile ? "80%" : "90%" } 
            p="2rem" 
            m="2rem auto" 
            mt={isNotMobile ? 18 : 14}
            mb={isNotMobile ? "60vh" : 25}
            borderRadius={5} 
            backgroundColor={theme.palette.background.alt} 
            sx={{boxShadow:5}}
        >
            <Collapse in={error}>
                <Alert severity="error" sx={{mb:2}}>{error}</Alert>
            </Collapse>
            <form onSubmit={(e) => e.preventDefault()}>
                <Typography variant={isNotMobile ? "h2" : "h4"} fontWeight="500" textAlign="center" color="primary" mb={3}>Partner Dashboard</Typography>


                <Stack direction="row" alignItems="center" spacing={1} my={2}>
                        <Typography variant={isNotMobile ? "h5" : "h6"} fontWeight="bold" mr={0.5}>Referral Code:</Typography>
                        <Typography color="primary" fontWeight="bold">{partnerCode}</Typography>
                        <IconButton onClick={() => {navigator.clipboard.writeText(partnerCode); alert('Partner code copied.')}}>
                            <ContentCopyIcon sx={{fontSize:20}}/>
                        </IconButton>
                </Stack>

                <Divider sx={{ mt:1, mb:2}}></Divider>
                
                <Typography variant={isNotMobile ? "h5" : "h6"}  fontWeight="bold" mb={1}>User Lookup</Typography> 
                
                <TextField sx={{".MuiOutlinedInput-root": { fontSize: isNotMobile ? 16 : 14, borderRadius: 10 }, mb:isNotMobile ? 2 : 1}} 
                required size={isNotMobile ? "medium" : "small"} placeholder="Search by email or subscription type" fullWidth value={query} 
                onChange={(e) => {setQuery(e.target.value);}} disabled={loading}
                InputProps={{
                    startAdornment: (
                    <InputAdornment >
                        <IconButton sx={{mr: 1}}>
                            <SearchIcon  sx={{color: loading? "gray" : "primary.main",}}/>
                        </IconButton>
                    </InputAdornment>
                    ),
                }}
                />
            </form>

            { filteredUsers && filteredUsers.length > 0 &&
                <Box width="100%">

                    <Typography variant={isNotMobile ? "h5" : "h6"} fontWeight="bold" my={2}> Referred Users ({filteredUsers.length} total) </Typography>

                    <div style={{ height: 'auto', width: '100%' }}>
                        <DataGrid
                            rows={mappedUsers}
                            columns={columns}
                            pageSize={10}
                        />
                    </div>

                    <Button variant="contained" onClick={() => { window.scrollTo({top: 0, behavior: "smooth"});}} disableElevation={true}
                        sx={{ fontWeight:"bold", textTransform: 'none', mt:2, fontSize: 14, borderRadius: 10, width: 140}}
                        startIcon={ <KeyboardDoubleArrowUpRoundedIcon/> } 
                    > 
                        Back to top
                    </Button>
                </Box>
            }
        </Box>
    )
}

export default PartnerDashboard;
